<template>
  <div>
    <a-form layout="vertical">
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item
            label="Nama Insentif"
          >
            <a-input v-model="newInsentif.nama" size="large" />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script>
export default {
  props: {
    newInsentif: {
      type: Object,
      required: true,
    },
  },
  methods: {
    // handleChange(value, type, column, data) {
    //   const payload = {
    //     value,
    //     type,
    //     column,
    //     data,
    //   }
    //   this.$emit('handle-change', payload)
    // },
  },
}
</script>

<style>

</style>
